import React from 'react';
import styled from 'styled-components';
import { layout, position } from 'styled-system';
import { useTranslation } from 'react-i18next';

import { Box } from '@core';
import { filteredProps } from '@shared/style-helpers';
import { isLTR } from '@shared/translation-helpers';

const StyledShapeWrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !filteredProps.includes(prop) && defaultValidatorFn(prop),
})`
  & > svg,
  & > img {
    position: absolute;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 50%;
    transform: ${({ transform, language }) =>
      `${transform ? transform : 'translateX(-50%)'} ${isLTR(language) ? '' : 'scaleX(-1)'}`};

    ${position}
    ${layout}
  }
`;

const ShapeWrapper = ({ shape: Shape, shapeProps, wrapperProps, children }) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Box position="relative" overflow="hidden" {...wrapperProps}>
      {Shape && (
        <StyledShapeWrapper {...shapeProps} language={language}>
          <Shape />
        </StyledShapeWrapper>
      )}
      {children}
    </Box>
  );
};

export default ShapeWrapper;
